import Navbar from "./scenes/Navbar";
import Landing from "./scenes/Landing";
import About from "./scenes/About";
import Contact from "./scenes/Contact";
import Footer from "./scenes/Footer";
import { Route, Routes } from "react-router-dom";
import Projects from "./scenes/Products";

function App() {

  return (
    <div className="app">

      <Navbar
      />
      <Routes>
        <Route path="*" element={<Landing />} />
        <Route path="/products" element={<Projects />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
