import { useState } from "react";
import { useTranslation } from "react-i18next";
import SocialMediaIcons from "../components/SocialMediaIcons";
import ItemsContainer from "./FooterItems/ItemContainer";
import PrivacyPolicy from "./privacyPolicy";

const Footer = () => {
  const [t] = useTranslation('global');

  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  return (
    <div>
      <PrivacyPolicy onClose={handleOnClose} visible={showMyModal} />
      <footer className="flex bg-black bg-opacity-1 pt-10">
        <div className="w-10/12 mx-auto">
          <ItemsContainer />
          <div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10
      text-center pt-2 text-white font-bold text-sm pb-8"
          >
            <span>© 2023. {t('footer.text.allRights')}.</span>
            <button onClick={() => setShowMyModal(true)}>Terms · Privacy Policy</button>

            <SocialMediaIcons />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;