import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";

import "../index.css"
import urun1 from '../assets/urunler/ürün1.webp';
import urun2 from '../assets/urunler/ürün2.webp';
import urun3 from '../assets/urunler/ürün3.webp';
import urun4 from '../assets/urunler/ürün4.webp';
import urun5 from '../assets/urunler/ürün5.webp';
import urun6 from '../assets/urunler/ürün6.webp';
import urun7 from '../assets/urunler/ürün7.webp';
import urun8 from '../assets/urunler/ürün8.webp';
import { useTranslation } from "react-i18next";




const Projects = () => {
  const [t] = useTranslation('global');

  // const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="products" className="pt-48 pb-48 px-8">
      {/* HEADINGS */}
      <motion.div
        className="md:w-2/5 mx-auto text-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <div>
          <p className="font-playfair font-semibold text-4xl">
            <span className="text-black">{t('product.text.protitle')}</span>
            <span className="text-red">{t('product.text.ductstitle')}</span>
          </p>
          <div className="flex justify-center mt-5">
            <LineGradient width="w-2/3" />
          </div>
        </div>

      </motion.div>
      <div className="md:flex md:justify-center mt-24 lg:mt-4">
        <div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-40 pt-4"

        >
          <div

            className='lg:w-[500px] lg:h-[400px] w-full p-2 bg-white rounded-xl transform transition-all hover:-translate-y-2 duration-300 shadow-lg hover:shadow-2xl'
          >

            <img class="h-[280px] object-cover rounded-xl"
              alt="urun1"
              src=
              {urun1} />

            <div className='mt-2 ml-2'>
              <h5 className='text-lg font-bold'>{t('product.text.urun1')}</h5>
              <p className='mt-1 mb-1 text-slate'>
                Box Size: 160x150x110
              </p>
              <a
                target="_blank" rel="noopener noreferrer"
                href="https://www.pacdora.com/share?filter_url=psgi6tits9"
                >
                  <p className="font-semibold">  {t('product.text.3dclick')}</p>

              </a>
            </div>
          </div>

          <div

            className='lg:w-[500px] lg:h-[400px] w-full p-2 bg-white rounded-xl transform transition-all hover:-translate-y-2 duration-300 shadow-lg hover:shadow-2xl'
          >

            <img class="h-[280px] object-cover rounded-xl"
              alt="urun2"
              src=
              {urun2} />

            <div className='mt-2 ml-2'>
              <h5 className='text-lg font-bold'>{t('product.text.urun2')}</h5>
              <p className='mt-1 mb-1 text-slate'>
                Box Size: 320x320x130
              </p>
              <a
                target="_blank" rel="noopener noreferrer"
                href="https://www.pacdora.com/share?filter_url=psrhflp47y"
                >
                  <p className="font-semibold">  {t('product.text.3dclick')}</p>

              </a>
            </div>
          </div>

          <div

            className='lg:w-[500px] lg:h-[400px] w-full p-2 bg-white rounded-xl transform transition-all hover:-translate-y-2 duration-300 shadow-lg hover:shadow-2xl'
          >

            <img class="h-[280px] object-cover rounded-xl"
              alt="urun3"
              src=
              {urun3} />

            <div className='mt-2 ml-2'>
              <h5 className='text-lg font-bold'>{t('product.text.urun3')}</h5>
              <p className='mt-1 mb-1 text-slate'>
                Box Size: 310x200x70
              </p>
              <a
                target="_blank" rel="noopener noreferrer"
                href="https://www.pacdora.com/share?filter_url=pstshgryfs"
                >
                  <p className="font-semibold">  {t('product.text.3dclick')}</p>

              </a>
            </div>
          </div>

          <div

            className='lg:w-[500px] lg:h-[400px] w-full p-2 bg-white rounded-xl transform transition-all hover:-translate-y-2 duration-300 shadow-lg hover:shadow-2xl'
          >

            <img class="h-[280px] object-cover rounded-xl"
              alt="urun4"
              src=
              {urun4} />

            <div className='mt-2 ml-2'>
              <h5 className='text-lg font-bold'>{t('product.text.urun4')}</h5>
              <p className='mt-1 mb-1 text-slate'>
                Box Size: 310x200x60
              </p>
              <a
                target="_blank" rel="noopener noreferrer"
                href="https://www.pacdora.com/share?filter_url=psi3f86q7o"
                >
                  <p className="font-semibold">  {t('product.text.3dclick')}</p>

              </a>
            </div>
          </div>

          <div

            className='lg:w-[500px] lg:h-[400px] w-full p-2 bg-white rounded-xl transform transition-all hover:-translate-y-2 duration-300 shadow-lg hover:shadow-2xl'
          >

            <img class="h-[280px] object-cover rounded-xl"
              alt="urun5"
              src=
              {urun5} />

            <div className='mt-2 ml-2'>
              <h5 className='text-lg font-bold'>{t('product.text.urun5')}</h5>
              <p className='mt-1 mb-1 text-slate'>
                Box Size: 400x600x400
              </p>
              <a
                target="_blank" rel="noopener noreferrer"
                href="https://www.pacdora.com/share?filter_url=psscoo1lll"
                >
                  <p className="font-semibold">  {t('product.text.3dclick')}</p>

              </a>
            </div>
          </div>

          <div

            className='lg:w-[500px] lg:h-[400px] w-full p-2 bg-white rounded-xl transform transition-all hover:-translate-y-2 duration-300 shadow-lg hover:shadow-2xl'
          >

            <img class="h-[280px] object-cover rounded-xl"
              alt="urun6"
              src=
              {urun6} />

            <div className='mt-2 ml-2'>
              <h5 className='text-lg font-bold'>{t('product.text.urun6')}</h5>
              <p className='mt-1 mb-1 text-slate'>
                Box Size: 110x110x90
              </p>
              <a
                target="_blank" rel="noopener noreferrer"
                href="https://www.pacdora.com/share?filter_url=pss0wylw9v"
                >
                  <p className="font-semibold">  {t('product.text.3dclick')}</p>

              </a>
            </div>
          </div>

          <div

            className='lg:w-[500px] lg:h-[400px] w-full p-2 bg-white rounded-xl transform transition-all hover:-translate-y-2 duration-300 shadow-lg hover:shadow-2xl'
          >

            <img class="h-[280px] object-cover rounded-xl"
              alt="urun7"
              src=
              {urun7} />

            <div className='mt-2 ml-2'>
              <h5 className='text-lg font-bold'>{t('product.text.urun7')}</h5>
              <p className='mt-1 mb-1 text-slate'>
                Box Size: 153x371x91
              </p>
              <a
                target="_blank" rel="noopener noreferrer"
                href="https://www.pacdora.com/share?filter_url=psmnrwi1n3">
                  <p className="font-semibold">  {t('product.text.3dclick')}</p>
              </a>
            </div>
          </div>

          <div

            className='lg:w-[500px] lg:h-[400px] w-full p-2 bg-white rounded-xl transform transition-all hover:-translate-y-2 duration-300 shadow-lg hover:shadow-2xl'
          >

            <img class=" h-[280px] object-cover rounded-xl"
              alt="urun8"
              src=
              {urun8} />

            <div className='mt-2 ml-2'>
              <h5 className='text-lg font-bold'>{t('product.text.urun8')}</h5>
              <p className='mt-1 mb-1 text-slate'>
                Box Size: 350x205x170
              </p>
              <a
                target="_blank" rel="noopener noreferrer"
                href="https://www.pacdora.com/share?filter_url=psjpqm7kis"
                >
                  <p className="font-semibold">  {t('product.text.3dclick')}</p>

              </a>
            </div>
          </div>

        </div>

      </div>

    </section>
  );
};

export default Projects;
