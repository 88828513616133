import { useState } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import logo from '../assets/mfs-logo.webp'
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Link = ({ to, page, selectedPage, setSelectedPage }) => {
  const lowerCasePage = to;
  return (
    <NavLink
      className={`${selectedPage === lowerCasePage ? "text-red" : ""
        } hover:text-red transition duration-500`}
      href={`#${lowerCasePage}`}
      to={lowerCasePage}
      onClick={() => setSelectedPage(lowerCasePage)}
    >
      {page}
    </NavLink>
  );
};

const Navbar = ({ isTopOfPage, selectedPage, setSelectedPage }) => {
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const navbarBackground = isTopOfPage ? "" : "bg-white";
  const [t, i18n] = useTranslation();

  const handleChangeLang = (lang) => {
    i18n.changeLanguage(lang);
  }
  return (
    <nav className={`${navbarBackground} z-40 w-full fixed top-0  px-8`}>
      <div className="flex items-center justify-between mx-auto">
        <a href="/" >
          <img className="w-44 py-6" src={logo} alt="MFSlogo"></img>
        </a>
        {/* DESKTOP NAV */}
        {isDesktop ? (
          <div className="flex ">
            <div className="flex justify-between items-center gap-16 lg:mr-6 font-opensans text-md font-semibold">
              <Link
                to={"/"}
                page={t('navbar.titles.home')}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
              <Link
                to={"/about"}
                page={t('navbar.titles.about')}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
              <Link
                to={"/products"}
                page={t('navbar.titles.products')}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />


              <Link
                to={"/contact"}
                page={t('navbar.titles.contact')}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />


            </div>
            <div className="ml-8 grid grid-cols-4 "
            >

              <button onClick={() => handleChangeLang("en")} className="text-md text-[#454545] hover:text-red transition duration-500 font-semibold ">EN</button>
              <p> </p>
              <p>|</p>
              <button onClick={() => handleChangeLang("tr")} className="text-md text-[#454545] hover:text-red transition duration-500 font-semibold ">TR</button>
            </div>
          </div>
        ) : (
          <button
            className="rounded-full bg-red p-2"
            onClick={() => setIsMenuToggled(!isMenuToggled)}
          >
            <img alt="menu-icon" src="../assets/menu-icon.svg" />
          </button>
        )}

        {/* MOBILE MENU POPUP */}
        {!isDesktop && isMenuToggled && (
          <div className="fixed right-0 bottom-0 h-full bg-[#454545] w-[300px]">
            {/* CLOSE ICON */}
            <div className="flex justify-end p-12">
              <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                <img alt="close-icon" src="../assets/close-icon.svg" />
              </button>
            </div>

            {/* MENU ITEMS */}
            <div className="flex flex-col gap-10 ml-[33%] text-2xl text-white">
            <div className=" grid grid-cols-4 mr-20 mb-8"
            >

              <button onClick={() => handleChangeLang("en")} className="text-md text-white hover:text-red transition duration-500 font-semibold ">EN</button>
              <p> </p>
              <p>|</p>
              <button onClick={() => handleChangeLang("tr")} className="text-md text-white hover:text-red transition duration-500 font-semibold ">TR</button>

            </div>

              <Link
                to={"/"}
                page={t('navbar.titles.home')}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
              <Link
                to={"/about"}
                page={t('navbar.titles.about')}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
              <Link
                to={"/products"}
                page={t('navbar.titles.products')}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />


              <Link
                to={"/contact"}
                page={t('navbar.titles.contact')}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </div>
            
          </div>
        )}
      </div>
    </nav >
  );
};

export default Navbar;
