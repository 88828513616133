import { motion } from "framer-motion";
import mfsSirket from '../assets/mfsSirket.webp';

import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import box13 from '../assets/box13.webp';
import kalip from '../assets/kalip.webp'
import kisaVideo1 from '../assets/kisaVideo1.mp4';
import kisaVideo2 from '../assets/kisaVideo2.mp4';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../index.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import { TypeAnimation } from 'react-type-animation';
import { useTranslation } from "react-i18next";


const Landing = ({ setSelectedPage }) => {
  const [t] = useTranslation('global');


  return (
    <section className="lg:px-12 px-2 lg:py-36 py-48">
      <div
        id="home"
        className="md:flex md:justify-between xl:justify-center md:items-center xl:gap-56 gap-16"
      >
        {/* IMAGE SECTION */}
          <div className=" z-10 mt-16 md:mt-4 flex justify-center md:order-2">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 1 }}
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
              }}
            >
              <div
                className=" w-[379px] lg:w-[650px] xl:w-[660px] xl:h-[490px] relative z-0 before:absolute before:-top-2 before:left-2  before:rounded-sm
            lg:before:w-full  md:before:max-w-2xl lg:before:h-full lg:before:border-8 before:border-opacity-50 before:border-green before:z-[-1]"
              >
                <div className="flex justify-center">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 3400,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img className="h-full" src={mfsSirket} alt='MFS Sirket' />
              </SwiperSlide>
              <SwiperSlide>
                <video className="h-full w-full" playsinline loop muted autoPlay controller={false} src={kisaVideo1}>

                </video>                  </SwiperSlide>
              <SwiperSlide>
                <video className="h-full w-full" playsinline loop muted autoPlay controller={false} src={kisaVideo2}>

                </video>
              </SwiperSlide>



            </Swiper>
          </div>
              </div>
            </motion.div>
          </div>
          


        {/* MAIN TEXT */}
        <div className="z-30 basis-2/5 mt-12 lg:mt-32">
          {/* HEADINGS */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 1 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
              
            }}
          >
            <p className="text-6xl z-10 text-center lg:text-start">
              
              <span
                className="relative text-black font-semibold z-20 
              before:absolute before:-left-[px] before:-top-[50px] before:z-[-1]"
              >
            
                <TypeAnimation
                  sequence={[
                    // Same substring at the start will only be typed out once, initially
                    ' KALİTE',
                    1500, // wait 1s before replacing "Mice" with "Hamsters"
                    ' QUALITY',
                    1500,
                    ' CALIDAD',
                    1500,
                    ' QUALITÄT',
                    1500,
                    ' جودة',
                    1500,
                    ' КАЧЕСТВО',
                    1500,
                    ' QUALITÀ',
                    1500,
                    ' JAKOŚĆ',
                    1500,
                    ' クオリティ',
                    1500,
                    ' QUALIDADE',
                    1500,
                    ' 质量',
                    1500,
                    ' KWALITEIT',
                    1500,
                    ' QUALITÉ',
                    1500,

                  ]}
                  wrapper="span"
                  speed={180}
                  repeat={Infinity}
                />
              </span>
            </p>

            <p className="mt-10 mb-7 font-semibold text-2xl text-center lg:text-start">
              {t('landing.text.p1')}
            </p>
          </motion.div>

          {/* CALL TO ACTIONS */}
          <motion.div
            className="flex mt-5 justify-center md:justify-start"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 1.25 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <NavLink
              className="bg-red bg-opacity-80 text-deep-blue rounded-sm py-3 px-7 font-semibold
              hover:bg-[#454545] hover:text-white transition duration-500"
              onClick={() => setSelectedPage("contact")}
              style={{ border: 0, borderRadius: 5 }}
              to="/contact"
            >
              {t('landing.text.contactusBTN')}
            </NavLink>
            <NavLink
              className="bg-red bg-opacity-80 ml-3 text-deep-blue rounded-sm py-3 px-7 font-semibold
            hover:bg-[#454545] hover:text-white transition duration-500"
              onClick={() => setSelectedPage("products")}
              style={{ border: 0, borderRadius: 5 }}
              to="/products"
            >
              {t('landing.text.productsBTN')}

            </NavLink>
          </motion.div>


        </div>
      </div>

      <div className="md:flex justify-center 
      mt-20 lg:mt-60 xl:mt-96 mb-30 lg:gap-32 xl:gap-8 xl:ml-[120px]">
        <motion.div
          className="lg:w-1/3 justify-between mt-0"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="w-[370px] h-32 relative">
            <div className="z-10">
              <div className="w-[349px] lg:w-[370px] h-32 bg-green bg-opacity-75 rounded-md absolute right-0 top-0 z-[-1]">
                <p className="font-playfair text-center font-semibold text-3xl mt-10 text-white">
                {t('landing.text.durability')}
                </p>
              </div>
            </div>
          </div>
          <p className="px-[20px] xl:w-[390px] mt-6 lg:mt-5 text-xl font-semibold text-start">
            {t('landing.text.p2')}
          </p>
        </motion.div>
        <motion.div
          className="lg:w-1/3 mt-0"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="w-[370px] relative h-32 mt-12 lg:mt-0">
            <div className="z-10">
              <div className="w-[349px] lg:w-[375px] h-32 bg-red bg-opacity-75 rounded-md absolute right-0 top-0 z-[-1]">
                <p className="font-playfair text-center font-semibold text-3xl mt-10 text-white">
                {t('landing.text.customisation')}
                </p>
              </div>
            </div>
          </div>
          <p className="px-[20px] xl:w-[390px] mt-6 lg:mt-5 text-xl font-semibold text-start">
            {t('landing.text.p3')}        
          </p>
        </motion.div>
        <motion.div
          className="lg:w-1/3 mt-0"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="w-[370px] relative h-32 mt-12 lg:mt-0">
            <div className="z-10">
              <div className="w-[349px] lg:w-[375px] h-32 bg-[#454545] rounded-md absolute right-0 top-0 z-[-1]">
                <p className="font-playfair text-center font-semibold text-3xl mt-10 text-white">
                {t('landing.text.costEffici')}
                </p>
              </div>
            </div>
          </div>
          <p className="px-[20px] xl:w-[390px] mt-6 lg:mt-5 text-xl font-semibold text-start">
          {t('landing.text.p4')}
            </p>
        </motion.div>
      </div>
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex gap-72 lg:mt-60 mt-20 xl:ml-[100px] xl:gap-56">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 1 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <div className="mt-16 md:mt-0 justify-center flex">
              <div
                className="relative z-0 before:absolute before:-top-2 before:-left-2  before:rounded-sm
              lg:before:w-full lg:before:h-full md:before:max-w-[600px] xl:before:max-w-[750px] lg:before:border-8 before:border-red before:border-opacity-50 before:z-[-1]"
              >
                <img
                  alt="mfs kalip"
                  className="z-10 w-[379px] lg:w-[600px] xl:w-[750px]"
                  src={kalip}
                />
              </div>
           
          </div>
        </motion.div>

        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 1 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <div className="px-[10px] flex items-center justify-center lg:mr-6 lg:mt-20 mt-20">
            <p className=" text-2xl font-semibold text-start">
            {t('landing.text.p5')}
              </p>
          </div>
        </motion.div>
      </div>
      <div className="md:flex gap-80 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 1 }}
          variants={{
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <div className="px-[10px] flex items-center justify-center xl:mt-64 xl:ml-36 lg:ml-20 lg:mt-20">
            <p className=" text-2xl font-semibold text-start">
            {t('landing.text.p6')}
              </p>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 1 }}
          variants={{
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <div className="mt-16 md:mt-0 xl:mr-[100px] xl:mt-36 justify-center flex">
              <div
                className="relative z-0 before:absolute before:top-2 before:left-2  before:rounded-sm
                lg:before:w-full lg:before:h-full  md:before:max-w-[600px] xl:before:max-w-[750px] before:border-8 before:border-red before:border-opacity-75 before:z-[-1]"
                >
                <img
                  alt="mfs kutu"
                  className="z-10 w-[379px] lg:w-[600px] lg:h-[400px] xl:w-[750px]"
                  src={box13}
                />
              </div>
           
          </div>
        </motion.div>

      </div>

      {/* SKILLS */}
      {/* <div className="slider">
        <div className="slide-track">
          <div className="slide justify-center flex">
            <img src={vaden} height={"100"} width={"350"} alt="vaden" />
          </div>
          <div className="slide justify-center flex">
            <img src={ttt} height={"100"} width={"350"} alt="ttt" />
          </div>
          <div className="slide justify-center flex">
            <img src={yavuzsan} height={"100"} width={"350"} alt="yavuzsan" />
          </div>
          <div className="slide justify-center flex">
            <img src={benefit} height={"100"} width={"350"} alt="benefit"/>
          </div>
          <div className="slide justify-center flex">
            <img src={gewinner} height={"100"} width={"350"} alt="gewinner" />
          </div>
          <div className="slide justify-center flex">
            <img src={ekersan} height={"100"} width={"350"} alt="ekersan" />
          </div>
          <div className="slide justify-center flex">
            <img src={buyuk} height={"100"} width={"350"} alt="buyuk" />
          </div>
          <div className="slide justify-center flex">
            <img src={guney} height={"100"} width={"350"} alt="guney" />
          </div>
          <div className="slide justify-center flex">
            <img src={eker} height={"100"} width={"350"} alt="eker"/>
          </div>
          <div className="slide justify-center flex">
            <img src={grower} height={"100"} width={"350"} alt="grower" />
          </div>
          <div className="slide justify-center flex">
            <img src={ef} height={"100"} width={"350"} alt="ef" />
          </div>
          <div className="slide justify-center flex">
            <img src={bgs} height={"100"} width={"350"} alt="bgs" />
          </div>
          <div className="slide justify-center flex">
            <img src={waspo} height={"100"} width={"350"} alt="waspo" />
          </div>
          <div className="slide justify-center flex">
            <img src={vaden} height={"100"} width={"350"} alt="vaden" />
          </div>
          <div className="slide justify-center flex">
            <img src={ttt} height={"100"} width={"350"} alt="ttt" />
          </div>
          <div className="slide justify-center flex">
            <img src={yavuzsan} height={"100"} width={"350"} alt="yavuzsan" />
          </div>
          <div className="slide justify-center flex">
            <img src={benefit} height={"100"} width={"350"} alt="benefit"/>
          </div>
          <div className="slide justify-center flex">
            <img src={gewinner} height={"100"} width={"350"} alt="gewinner" />
          </div>
          <div className="slide justify-center flex">
            <img src={ekersan} height={"100"} width={"350"} alt="ekersan" />
          </div>
          <div className="slide justify-center flex">
            <img src={buyuk} height={"100"} width={"350"} alt="buyuk" />
          </div>
          <div className="slide justify-center flex">
            <img src={guney} height={"100"} width={"350"} alt="guney" />
          </div>
          <div className="slide justify-center flex">
            <img src={eker} height={"100"} width={"350"} alt="eker"/>
          </div>
          <div className="slide justify-center flex">
            <img src={grower} height={"100"} width={"350"} alt="grower" />
          </div>
          <div className="slide justify-center flex">
            <img src={ef} height={"100"} width={"350"} alt="ef" />
          </div>
          <div className="slide justify-center flex">
            <img src={bgs} height={"100"} width={"350"} alt="bgs" />
          </div>
          <div className="slide justify-center flex">
            <img src={waspo} height={"100"} width={"350"} alt="waspo" />
          </div>

        </div>
      </div> */}
    </section>
  );
};

export default Landing;
