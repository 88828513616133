import { useTranslation } from "react-i18next";
import Item from "./Item";
import { CONTACT, PAGES } from "./Menus";
const ItemsContainer = () => {
  const [t] = useTranslation('global');

  
   const PAGES = [
    
    { name: `${t('navbar.titles.home')}`, link: "/" },
    { name: `${t('navbar.titles.about')}`, link: "/about" },
    { name: `${t('navbar.titles.products')}`, link: "/products" },
    { name: `${t('navbar.titles.contact')}`, link: "/contact" },
  ];

   const CONTACT = [
    { name: "Fevzi Çakmak Mah.10641 Sk. No:9 Karatay / KONYA", link: "#" },
    { name: "Tel-Fax: 0332 345 43 03", link: "#" },


  ];
  
   const Icons = [
    { name: "logo-facebook", link: "#" },
    { name: "logo-twitter", link: "#" },
    { name: "logo-linkedin", link: "#" },
    { name: "logo-instagram", link: "#" },
  ];

  return (
    <div className="text-white grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-2 sm:px-8 gap-6 lg:place-items-center py-16 ">
      <Item Links={PAGES} title={t('footer.text.pages')} />
      <Item Links={CONTACT} title={t('footer.text.contact')} />
    </div>
  );
};



export default ItemsContainer;